<template>
  <Commanator />
</template>

<script>
import Commanator from './components/Commanator.vue'

export default {
  name: 'App',
  components: {
    Commanator
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-top: 60px;
}
</style>
