<template>
  <div :style="textColor">
    <h3>Commanator</h3>
    <textarea :class="inputClass" v-model="input" :placeholder="placeholder" rows="10" spellcheck="false"></textarea>
    <ul class="flex-container">
      <li class="flex-item">
        <h4>Theme</h4>
        <div v-on:click="switchTheme" style="cursor:pointer;">{{dark ? '☀️' :'🌙'}}</div> 
      </li>

      <li class="flex-item">
        <h4>Also split on</h4>
        <span v-on:click="spaces = !spaces" :style="`cursor:pointer;${truStyle(spaces)}`">Space</span>
        &nbsp;&nbsp;&nbsp;
        <span v-on:click="customSplit = !customSplit" :style="`cursor:pointer;${truStyle(customSplit)}`">Custom</span>        
        <div v-if="customSplit">
          Characters: <input v-model="customSplitChars" maxlength="5" size="5" :class="darkClass" />
        </div>
      </li>

      <li class="flex-item">
        <h4>Output Style</h4>
        <span v-on:click="picked = 'Single'" :style="`cursor:pointer;${pickStyle('Single')}`">'a','b'</span>&nbsp;&nbsp;&nbsp;
        <span v-on:click="picked = 'Double'" :style="`cursor:pointer;${pickStyle('Double')}`">"a","b"</span>&nbsp;&nbsp;&nbsp;
        <span v-on:click="picked = 'None'" :style="`cursor:pointer;${pickStyle('None')}`">a,b</span>&nbsp;&nbsp;&nbsp;
        <span v-on:click="picked = 'Custom'" :style="`cursor:pointer;${pickStyle('Custom')}`">Custom</span>&nbsp;&nbsp;&nbsp;        
        <div v-if="picked==='Custom'">
          Left: <input v-model="customQuoteLeft" maxlength="3" size="3" :class="darkClass" />
          Right: <input v-model="customQuoteRight" maxlength="3" size="3" :class="darkClass" /> 
          Separator: <input v-model="customSep" maxlength="3" size="3" :class="darkClass" />
        </div>
      </li>
    </ul>
  
    <h3>Result</h3>
    <div class="res">
        {{output}}
    </div>
    <div v-if="count>0">{{count}} item<span v-if="count>1">s</span></div>
  </div>
</template>

<script>
const qMap = {Single:`'`,Double:`"`,None:''}

const phWrapText = `multiple
lines
of
elements`;

const phWrapSpace = `
or elements separated by    space`;

const phWrapCustom = `
or elements separated with `;

export default {
  mounted() {
    // Check preferred theme, flip to light if they were in light mode last time
    if (localStorage.getItem('commTheme') === 'false') {
      this.switchTheme();
    }
  },
  data() {
    return {
      input: '',
      picked: 'Single',
      customQuoteLeft: '"',
      customQuoteRight: '"',
      customSplit: false,
      customSplitChars: ',',
      customSep: ',',
      spaces: false,
      dark: true
    }
  },
  methods: {
    switchTheme() {
      this.dark = !this.dark;
      document.querySelector('body').style=`background-color:${this.dark === false ? '#FFFFFF' : '#1A1A1E'}`;
      localStorage.setItem('commTheme', this.dark);
    },
    truStyle(value) {
      return value === true ? 'color:green;font-weight:bold' : '';
    },
    pickStyle(value) {
      return this.truStyle(value === this.picked);
    }
  },
  computed: {
    q() {
      return qMap[this.picked];      
    },
    ql() {
      return (this.picked === 'Custom' ? this.customQuoteLeft : this.q);
    },
    qr() {
      return (this.picked === 'Custom' ? this.customQuoteRight : this.q);
    },
    sep() {
      return (this.picked === 'Custom' ? this.customSep : ',');
    },
    placeholder() {
      return `${phWrapText}${this.spaces ? phWrapSpace : ''}${this.customSplit && this.customSplitChars?.length > 0 ? phWrapCustom + this.customSplitChars.split('').join(' or ') : ''}`;
    },
    rx() {
      // add \s for whitespace
      const ws = this.spaces ? '\\s' : '';
      // add custom split chars if applicable
      const cs = this.customSplit ? this.customSplitChars.replace('\\','\\\\') : '';
      // always split on line breaks, add whitespace and custom if set
      return new RegExp(`[\\n${ws}${cs}]+`,'gm');
      //return this.spaces ? /[\n\s]+/gm : /\n+/gm;
    },
    items() {
      return this.input
          .trim()
          .split(this.rx)
          .map(x => x.trim())
          .filter(x => x !== '')
    },
    count() {
      return this.items.length;
    },
    output() {
      try {
        // base case - nothing useful, return empty string
        if (this.count === 0 || this.items[0] === '') return '';
        // if we have items, create the appropriate separator
        const joiner = `${this.qr}${this.sep}${this.ql}`;
        return `${this.ql}${this.items.join(joiner)}${this.qr}`;
      } catch (e) {
        return 'Error creating list'
      }
    },
    textColor() {
      return this.dark ? 'color: #dae0ee;' : 'color: #000';
    },
    inputClass() {
      return this.dark ? 'txt dark' : 'txt';
    },
    darkClass() {
      return this.dark ? 'dark' : '';
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 10px 0 0;
}
h4 {
  margin: 5px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.dark {
  background-color: #1A1A1E;
  color: #ffffff;
  border: 1px solid #e5ebf2;
}
.txt {
  width: 95%;
  height: 150px;
}
.txtContainer {
  margin: 0 10px 0 10px;
}
.res {
  border: 1px solid #e5ebf2;
  margin: 0 20px 0 20px;
  min-height: 100px;
  font-family: courier, serif;
  font-size: .77em;
  padding: 5px;
  overflow-wrap:break-word;
  text-align:left;
}
.flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;  
}
.flex-item {
  padding: 8px;
  width: 350px;
}
</style>
